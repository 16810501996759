import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useResetScroll from "../../utils/useResetScroll";

export default function Invite() {
  useResetScroll();
  
  const { userId } = useParams();

  console.log("ref", userId);

  useEffect(() => {
    if (userId) {
      setTimeout(() => {
        window.location.href = `otaconnect://ota/Compatibility?userId=${userId}`;
      }, 500); // Delay redirection to prevent blocking
    }
  }, [userId]);

  return (
    <div className="w-full p-5 h-[60px] flex items-center bg-white justify-between">
      Its Working!!
    </div>
  );
}
