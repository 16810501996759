import React, { useEffect, useState } from "react";
import { authConfig } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import useResetScroll from "../../utils/useResetScroll";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { useTranslation } from "react-i18next";

const DeleteUser = () => {
  const {
    i18n: { language },
  } = useTranslation();
  useResetScroll();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let timer = setTimeout(() => {
      setIsPageLoading(false);
    }, 500);
    return () => {
      setIsPageLoading(true);
      clearTimeout(timer);
    };
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const handleDeleteAccount = () => {
    if (!email.trim() || !reason.trim()) {
      alert("Please fill in all fields.");
      return;
    }
    console.log("Deleting account for:", email, "Reason:", reason);
    navigate('/login')
  };

  return isPageLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <div className="max-w-[450px] mx-auto p-5 flex flex-col items-center min-h-[100svh] otaPageBg">
      <p className="otaDark text-[24px] font-bold text-center my-10 mt-18">
        <RenderTranslatedText i8nKey={"Delete your account"} />
      </p>
      <input
        type="text"
        placeholder="Enter your Email or LINE ID"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="w-full p-2 border rounded-xl mb-3"
      />
      <textarea
        placeholder="Reason for deleting account"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        className="w-full p-2 border rounded-xl mb-3 h-24"
      />
      <button
        onClick={handleDeleteAccount}
        className="text-red-500 text-sm text-center rounded-xl border-2 w-full border-red-500 p-2 mb-10 bg-white mx-4"
      >
        <RenderTranslatedText i8nKey={"delete_user_account"} />
      </button>
    </div>
  );
};

export default DeleteUser;
