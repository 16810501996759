import React, { useEffect, useState } from "react";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { useTranslation } from "react-i18next";
import OtaGradientButton from "../../components/OtaGradientButton";
import { useNavigate } from "react-router-dom";
import useResetScroll from "../../utils/useResetScroll";
import { communityNavConfig } from "../community/constants";
import { crown, TestQuizCategoryImg, TestQuizCategoryImg2 } from "../../assets";
import OtaGradientOutlineButton from "../../components/OtaGradientOutlineButton";
import axios from "axios";
import { otaQuizBackendBaseUrl } from "../../utils/auth";
import dayjs from "dayjs";


export default function Quiz() {
  useResetScroll();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState("top_nav_quiz");
  const [quizData, setQuizData] = useState([]);
  const [userRank, setUserRank] = useState(null);
  const [userScore, setUserScore] = useState(null);
  const [loading, setLoading] = useState(true); 
  const [myQuizData, setMyQuizData] = useState([]);
  const [randomQuiz, setRandomQuiz] = useState(null);
  const todayDate = dayjs().format("YYYY-MM-DD"); // Format date as YYYY-MM-DD


  const {
    t,
    i18n: { language },
  } = useTranslation();

  const handleNavClick = (item) => {
    navigate(`${item.path}`);
    setIsActive(item.name);
  };

  const handleQuizNavigation = async (quizId) => {
    const userId = localStorage.getItem("USER_ID");
    try {
      const response = await axios.get(
        `${otaQuizBackendBaseUrl.url}/${quizId}/${userId}/response-check`
      );

      if (response.data.exists) {
        navigate(`/quiz/${quizId}/response`);
      } else {
        navigate(`/quiz/${quizId}`);
      }
    } catch (error) {
      console.error("Error checking response:", error);
    }
  };

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const lang = language; // Get current language (either 'en' or 'ja')
        const response = await axios.get(`${otaQuizBackendBaseUrl.url}?lang=${lang}`);
        setQuizData(response.data);
      } catch (error) {
        console.error("Error fetching quizzes:", error);
      } finally {
        setLoading(false);
      }
    };
  
    const fetchUserQuizzes = async () => {
      const userId = localStorage.getItem("USER_ID");
      try {
        const response = await axios.get(`${otaQuizBackendBaseUrl.url}/user-quiz/${userId}?lang=${language}`);
        setMyQuizData(Array.isArray(response.data) ? response.data : []); // Ensure response is an array
      } catch (error) {
        console.error("Error fetching user quizzes:", error);
        setMyQuizData([]); // Default to an empty array on error
      }
    };    
  
    const fetchRandomQuizForUser = async () => {
      const userId = localStorage.getItem("USER_ID");
      try {
        const response = await axios.post(`${otaQuizBackendBaseUrl.url}/daily/quiz/${userId}`, {
          date: todayDate,
        });
        setRandomQuiz(response.data); // Set the random quiz data
      } catch (error) {
        console.error("Error fetching random quiz:", error);
      }
    };
  
    const fetchUserRanking = async () => {
      const userId = localStorage.getItem("USER_ID");
      try {
        const response = await axios.get(`${otaQuizBackendBaseUrl.url}/ranking/${userId}`);
        setUserRank(response.data.rank);
        setUserScore(response.data.cumulativeScore);
      } catch (error) {
        console.error("Error fetching user ranking:", error);
      }
    };
  
    fetchQuizzes();
    fetchUserQuizzes();
    fetchUserRanking();
    fetchRandomQuizForUser();
  }, [language,todayDate]);
  console.log("quizData",quizData);
  console.log("myQuizData",myQuizData);
  console.log("randomQuiz",randomQuiz);
  console.log("myQuizData:", myQuizData, "Type:", typeof myQuizData);
  const handleSeeAll = (category) => {
    navigate(`/quiz/category/${category}`);
  }; 

  return (
    <div className="otaPageBg w-full max-w-[450px] mx-auto min-h-[100svh]">
      <div className="w-full h-auto bg-white">
        <div className="w-full border-b-2">
          <p className="px-5 py-2 mb-2 w-full font-semibold text-[20px] text-[#414853]">
            <RenderTranslatedText i8nKey={"quiz_page_title"} />
          </p>
        </div>
        <div className="flex items-center justify-evenly border-b-2">
          {communityNavConfig?.map((item) => (
            <p
              key={item.path}
              onClick={() => handleNavClick(item)}
              className={`cursor-pointer px-4 py-2 ${
                isActive === item.name
                  ? "gradient-text font-bold border-b-4 border-gradient"
                  : "text-[#667085]"
              }`}
            >
              <RenderTranslatedText i8nKey={item.name} />
            </p>
          ))}
        </div>
      </div>

      <div className="p-5">
        {loading ? ( // Loading state
          <div className="flex justify-center items-center min-h-[50vh]">
            <p className=" text-3xl font-semibold">
              <RenderTranslatedText i8nKey={"loading_text"} />
            </p>
          </div>
        ) : (
          <>
            <div
  className={`flex w-full rounded-lg px-4 py-2 items-center border-2 border-[#FC8600] bg-[#F5E9DE] ${
    userScore && userRank ? "justify-between" : "justify-center"
  }`}
>
  {userScore && userRank && (
    <div className="flex space-x-2 items-center">
      <img src={crown} alt="crown" />
      <p className="text-[16px] text-[#344054]">
        {t("ranking_prefix")} {t("ranking_in_ranking")}{" "}
        <span className="text-[20px] font-semibold">
          {userRank || "--"}.
        </span>
        <br /> {t("ranking_with")}{" "}
        <span className="text-[20px] font-semibold">
          {userScore || "--"}
        </span>{" "}
        {t("ranking_points")}.
      </p>
    </div>
  )}
  <div
    onClick={() => navigate(`/quiz/ranking`)}
    className="cursor-pointer hover:underline text-[14px] otaFont font-semibold"
  >
    <RenderTranslatedText i8nKey={"quiz_page_view_ranking"} />
  </div>
</div>


            <div className="relative gradient rounded-xl my-4 border-2 border-[#7D2AE71F] p-3 text-center overflow-hidden">
              <div className="absolute top-[-40px] right-[-20px] w-[80px] h-[80px] bg-[#7D2AE714] rounded-full opacity-8 z-0"></div>
              <div className="absolute top-[-20px] right-[-30px] w-[80px] h-[80px] bg-[#7D2AE714] rounded-full opacity-8 z-0"></div>
              <div className="absolute bottom-[-5px] left-[-40px] w-[80px] h-[80px] bg-[#3969E714] rounded-full opacity-8 z-0"></div>
              <div className="absolute bottom-[-20px] left-[-20px] w-[80px] h-[80px] bg-[#3969E714] rounded-full opacity-8 z-0"></div>

              <p className="mb-2 font-bold text-[16px] relative z-10">
                <RenderTranslatedText i8nKey={"quiz_page_trivia_title"} />
              </p>
              <p className="text-[#344054] text-[12px] relative px-2 z-10">
                <RenderTranslatedText i8nKey={"quiz_page_trivia_subtitle"} />
              </p>
              {randomQuiz && (
          <OtaGradientOutlineButton
            onClick={() => handleQuizNavigation(randomQuiz.quizId)}
            className="mt-4 relative z-10"
          >
            <p className="font-semibold px-10">
              <RenderTranslatedText i8nKey={"quiz_page_play_now_cta"} />
            </p>
          </OtaGradientOutlineButton>
        )}
            </div>
            <div>
            {myQuizData.length > 0 && (
  <>
   <div className="w-full flex items-center justify-between px-1 mb-2 my-4 ">
  <p className="otaDark text-[16px] font-bold">
    <RenderTranslatedText i8nKey={"quiz_page_my_quiz"} />
  </p>
  <p
    className="otaFont underline text-[14px] font-semibold"
    onClick={() => handleSeeAll('my_quiz')}
  >
    <RenderTranslatedText i8nKey={"characters_create_page_see_all"} />
  </p>
</div>

    <div className="flex overflow-x-auto space-x-4 items-center px-1">
      {myQuizData.map((quiz) => (
        <div
          onClick={() => handleQuizNavigation(quiz.quizId)}
          key={quiz.quizId}
          className="relative w-[150px] h-[200px] rounded-lg overflow-hidden shadow-lg flex-shrink-0"
        >
          <img
            src={quiz.coverImg}
            alt={quiz.titleEn}
            className="w-full h-full object-cover"
          />
          <div className="absolute bottom-0 left-0 right-0 h-[200px] bg-gradient-to-t from-black to-transparent"></div>
          <p className="absolute bottom-2 left-2 text-white text-sm ">
            {quiz.titleEn || quiz.titleJa}
          </p>
        </div>
      ))}
    </div>
  </>
)}

{/* Filter quizzes for "Popular" */}
<div className="w-full flex items-center justify-between px-1 mb-2 my-4 ">
  <p className="otaDark text-[16px] font-bold">
    <RenderTranslatedText i8nKey={"quiz_page_popular"} />
  </p>
  <p
    className="otaFont underline text-[14px] font-semibold"
   
onClick={() => handleSeeAll('popular')}

  >
    <RenderTranslatedText i8nKey={"characters_create_page_see_all"} />
  </p>
</div>
<div className="flex overflow-x-auto space-x-4 items-center px-1 mb-4">
{quizData && quizData
  .filter((quiz) => Array.isArray(myQuizData) && !myQuizData.some((myQuiz) => myQuiz.quizId === quiz.quizId))
  .map((quiz) => (
    <div
      onClick={() => handleQuizNavigation(quiz.quizId)}
      key={quiz.quizId}
      className="relative w-[150px] h-[200px] rounded-lg overflow-hidden shadow-lg flex-shrink-0"
    >
      <img
        src={quiz.coverImg}
        alt={quiz.titleEn}
        className="w-full h-full object-cover"
      />
      <div className="absolute bottom-0 left-0 right-0 h-[200px] bg-gradient-to-t from-black to-transparent"></div>
      <p className="absolute bottom-2 left-2 text-white text-sm ">
        {quiz.titleEn || quiz.titleJa}
      </p>
    </div>
  ))}

</div>

</div>
          </>
        )}
      </div>
    </div>
  );
}