import React from "react";
import { useTranslation } from "react-i18next";
import useResetScroll from "../../utils/useResetScroll";

export default function PrivacyPolicy() {
  useResetScroll();
  const { t } = useTranslation();

  return (
    <div className="max-w-[450px] mx-auto px-5">
      <p className="text-[24px] font-semibold text-center my-10">
        {t("PRIVACY_POLICY")}
      </p>
      <p className="text-wrap">{t("INTRO")}</p>
      <section>
        <h3>{t("SECTION_1.TITLE")}</h3>
        <p>{t("SECTION_1.CONTENT")}</p>
      </section>
      <section>
        <h3>{t("SECTION_2.TITLE")}</h3>
        <p>{t("SECTION_2.CONTENT")}</p>
      </section>
      <section>
        <h3>{t("SECTION_3.TITLE")}</h3>
        <p>{t("SECTION_3.CONTENT")}</p>
      </section>
      <section>
        <h3>{t("SECTION_4.TITLE")}</h3>
        <p>{t("SECTION_4.CONTENT")}</p>
      </section>
    </div>
  );
}
