import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useResetScroll from "../../utils/useResetScroll";

export default function RankingRedirect() {
  useResetScroll();
  
  const { quizId } = useParams();

  useEffect(() => {
    if (quizId) {
      setTimeout(() => {
        window.location.href = `otaconnect://ota/QuizRanking?quizId=${quizId}`;
      }, 500); // Delay redirection to prevent blocking
    } else {
      setTimeout(() => {
          window.location.href = `otaconnect://ota/QuizRanking`;
        }, 500);
    }
  }, [quizId]);

  return (
    <div className="w-full p-5 h-[60px] flex items-center bg-white justify-between">
      Its Working!!
    </div>
  );
}
