import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useResetScroll from "../../utils/useResetScroll";

export default function LineLogin() {
  useResetScroll();
  
  const { code } = useParams();

  console.log("ref", code);

  useEffect(() => {
    if (code) {
      setTimeout(() => {
        window.location.href = `otaconnect://ota/LineLogin?code=${code}`;
      }, 500);
    }
  }, [code]);

  return (
    <div className="w-full p-5 h-[60px] flex items-center bg-white justify-between">
      Its Working!!
    </div>
  );
}
