import React from "react";
import useResetScroll from "../../utils/useResetScroll";

export default function CSAEPolicy() {
  useResetScroll();

  return (
    <div className="max-w-[450px] mx-auto px-5">
      <p className="text-[24px] font-semibold text-center my-10">
        Ota's Commitment to Preventing Child Sexual Abuse and Exploitation (CSAE)
      </p>
      <p className="text-wrap mb-4">
        At Ota, we are deeply committed to maintaining a safe and secure environment for all users of our platform. We strictly prohibit any form of child sexual abuse material (CSAM) or any activity related to the exploitation or abuse of children. Below are the standards we uphold to ensure the prevention of such behavior:
      </p>
      <section className="mb-4">
        <h3 className="font-bold">1. Zero Tolerance Policy</h3>
        <p>
          We maintain a zero-tolerance policy for child sexual abuse and exploitation. Any user found to be involved in such activities will be immediately banned and reported to relevant authorities.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="font-bold">2. Proactive Monitoring and Reporting</h3>
        <p>
          Our platform implements proactive measures to identify, monitor, and block inappropriate content or behavior. We cooperate fully with law enforcement agencies and international organizations to report and take action against any violations.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="font-bold">3. User Education and Awareness</h3>
        <p>
          We educate our users about the importance of online safety and provide guidelines to recognize and report suspicious or harmful activities.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="font-bold">4. Compliance with Laws</h3>
        <p>
          Ota strictly adheres to all local, national, and international laws concerning the prevention of child exploitation and abuse. We comply with the standards set by organizations such as the Internet Watch Foundation (IWF) and National Center for Missing & Exploited Children (NCMEC).
        </p>
      </section>
      <section className="mb-4">
        <h3 className="font-bold">5. Reporting Mechanism</h3>
        <p>
          We provide an easy-to-use reporting system for users to flag any content or behavior that violates our policies. Reports are reviewed promptly by our dedicated moderation team.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="font-bold">6. Partnerships with Industry Leaders</h3>
        <p>
          We collaborate with child protection organizations and technology partners to stay updated on best practices and technological advancements to combat CSAE.
        </p>
      </section>
      <section>
        <h3 className="font-bold">Contact and Support</h3>
        <p>
          If you suspect any violation of these standards on our platform, please contact us immediately at:
        </p>
        <p className="font-bold">Email: hiro.ganji@be-native.life</p>
        <p>
          Together, we can create a safer digital environment and take meaningful action against child sexual abuse and exploitation.
        </p>
      </section>
    </div>
  );
}